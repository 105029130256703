<template>
  <div class="shipping">
    <div class="facilityBox">
      <el-tabs v-model="activeCmpt" @tab-click="resetForm">
        <el-tab-pane
          :label="item.statusName"
          :name="item.status"
          v-for="(item, index) in statusList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <el-form
        class="manageForm"
        :model="queryForm"
        :inline="true"
        size="medium"
        label-width="140px"
      >
        <el-form-item label="船舶名称：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipName"
            placeholder="输入船舶名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶登记号：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipRegNumber"
            placeholder="输入船舶登记号"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶类型：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipType"
            placeholder="输入船舶类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="船舶编号：">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipNO"
            placeholder="输入船舶编号"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt + '：'"
        >
          <el-date-picker
            v-model="queryForm.searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <input
        type="file"
        style="display: none"
        @change="handleUploadData"
        ref="uploadData"
      />
      <div class="topbtns">
        <el-button
          type="success"
          size="medium"
          @click="toAdd('add', null)"
          icon="el-icon-circle-plus-outline"
          >添加船舶
        </el-button>
        <el-button
          icon="el-icon-wallet"
          type="primary"
          size="medium"
          @click="$refs.uploadData.click()"
          >上传船舶表格</el-button
        >
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="toDownTemplate"
          >下载船舶表格模板</el-button
        >
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="toExport"
          :disabled="!tableData.length"
          >导出船舶表格</el-button
        >
        <el-button
          icon="el-icon-collection"
          type="primary"
          size="medium"
          @click="toExamine('some', null)"
          :disabled="!multipleSelection.length"
          v-if="activeCmpt == 0"
          >批量提交审核</el-button
        >
        <el-button
          icon="el-icon-remove-outline"
          type="danger"
          size="medium"
          @click="toDelete('some', null)"
          :disabled="!multipleSelection.length"
          >批量删除</el-button
        >
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipRegNumber"
          label="船舶登记号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipType"
          label="船舶类型"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipNO"
          label="船舶编号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="StatusTime"
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="驳回原因"
          width="200"
          show-overflow-tooltip
          v-if="activeCmpt == '1'"
        ></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              v-if="scope.row.Status == 0"
              @click="toAdd('edit', scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="toDelete('one', scope.row)">删除</el-button>
            <el-button
              type="success"
              v-if="scope.row.Status == 0"
              @click="toExamineOne(scope.row)"
              >提交审核</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.Status == 10 || scope.row.Status == 100"
              @click="toDetail(scope.row)"
              >查看详情</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.Status == 1"
              @click="toAdd('edit', scope.row)"
              >重新提交</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          :page-sizes="[10, 50, 100, 200]"
          @size-change="sizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      class="showAddDialog"
      append-to-body
      :visible.sync="showAddDialog"
      width="50%"
      v-if="showAddDialog"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">{{
        dialogType == "detail" ? "查看详情" : !currentItem ? "新增船舶" : "编辑船舶"
      }}</span>
      <div class="body">
        <el-form
          class="addForm"
          :model="addForm"
          :rules="addRule"
          ref="addForm"
          :disabled="dialogType == 'detail'"
        >
          <div class="title">证件信息</div>
          <div class="content">
            <div class="upBox">
              <UploadImg
                :loading="authForm.RiverLicenseURL.loading"
                :uploaded="authForm.RiverLicenseURL.uploaded"
                :picUrl="authForm.RiverLicenseURL.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.RiverLicenseURL)"
                @deleteImg="deleteImg(authForm.RiverLicenseURL)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.RiverLicenseURL.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">船舶内河适航证 <span style="color: red">*</span></div>
            </div>
            <div class="upBox">
              <UploadImg
                :loading="authForm.ShipLicenseFrontURL.loading"
                :uploaded="authForm.ShipLicenseFrontURL.uploaded"
                :picUrl="authForm.ShipLicenseFrontURL.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.ShipLicenseFrontURL)"
                @deleteImg="deleteImg(authForm.ShipLicenseFrontURL)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.ShipLicenseFrontURL.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">船舶营运证正面 <span style="color: red">*</span></div>
            </div>
            <div class="upBox">
              <UploadImg
                :loading="authForm.ShipLicenseBackURL.loading"
                :uploaded="authForm.ShipLicenseBackURL.uploaded"
                :picUrl="authForm.ShipLicenseBackURL.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.ShipLicenseBackURL)"
                @deleteImg="deleteImg(authForm.ShipLicenseBackURL)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.ShipLicenseBackURL.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">船舶营运证反面 <span style="color: red">*</span></div>
            </div>
          </div>
          <div class="content" style="margin-top: 5px">
            <div class="upBox">
              <UploadImg
                :loading="authForm.ShipPhotoURL.loading"
                :uploaded="authForm.ShipPhotoURL.uploaded"
                :picUrl="authForm.ShipPhotoURL.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.ShipPhotoURL)"
                @deleteImg="deleteImg(authForm.ShipPhotoURL)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.ShipPhotoURL.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">船舶图片 <span style="color: red">*</span></div>
            </div>
            <div
              class="upBox"
              v-if="
                dialogType != 'detail' ||
                (dialogType == 'detail' && authForm.Aisurl.picUrl)
              "
            >
              <UploadImg
                :loading="authForm.Aisurl.loading"
                :uploaded="authForm.Aisurl.uploaded"
                :picUrl="authForm.Aisurl.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.Aisurl)"
                @deleteImg="deleteImg(authForm.Aisurl)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.Aisurl.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">AIS证书</div>
            </div>
            <div
              class="upBox"
              v-if="
                dialogType != 'detail' ||
                (dialogType == 'detail' && authForm.RadioLicenseURL.picUrl)
              "
            >
              <UploadImg
                :loading="authForm.RadioLicenseURL.loading"
                :uploaded="authForm.RadioLicenseURL.uploaded"
                :picUrl="authForm.RadioLicenseURL.picUrl"
                @update:file="updateFile"
                @change="uploadCertificate(authForm.RadioLicenseURL)"
                @deleteImg="deleteImg(authForm.RadioLicenseURL)"
                v-if="dialogType != 'detail'"
              >
              </UploadImg>
              <el-image
                :src="authForm.RadioLicenseURL.picUrl"
                :preview-src-list="srcList"
                class="img"
                v-else
              >
              </el-image>
              <div class="upText">电台执照</div>
            </div>
          </div>
          <div class="title">基本信息</div>
          <div class="content" style="justify-content: left">
            <el-form-item label="船舶名称" prop="ShipName">
              <el-input
                v-model="addForm.ShipName"
                placeholder="请输入船舶名称"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶登记号" prop="ShipRegNumber">
              <el-input
                v-model="addForm.ShipRegNumber"
                placeholder="请输入船舶登记号"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶类型" prop="ShipType">
              <el-input
                v-model="addForm.ShipType"
                placeholder="请输入船舶类型"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶编号" prop="ShipNO">
              <el-input
                v-model="addForm.ShipNO"
                placeholder="请输入船舶编号"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船籍港口" prop="ShipPort">
              <el-input
                v-model="addForm.ShipPort"
                placeholder="请输入船籍港口"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船检登记号" prop="ShipCheckNumber">
              <el-input
                v-model="addForm.ShipCheckNumber"
                placeholder="请输入船检登记号"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶所有人" prop="Shipowner">
              <el-input
                v-model="addForm.Shipowner"
                placeholder="请输入船舶所有人"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶经营人" prop="ShipOperator">
              <el-input
                v-model="addForm.ShipOperator"
                placeholder="请输入船舶经营人"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="使用期限" prop="UsagePeriodDate">
              <el-date-picker
                v-model="addForm.UsagePeriodDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择使用期限"
              ></el-date-picker>
              <!-- <el-input v-model="addForm.UsagePeriodDate" placeholder="请输入使用期限"></el-input> -->
            </el-form-item>
            <el-form-item label="发证机关" prop="IssuingAuthority">
              <el-input
                v-model="addForm.IssuingAuthority"
                placeholder="请输入发证机关"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="发证日期" prop="IssuingDate">
              <el-date-picker
                v-model="addForm.IssuingDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择发证日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="船舶材料" prop="ShipBodyMaterial">
              <el-input
                v-model="addForm.ShipBodyMaterial"
                placeholder="请输入船舶材料"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船舶总吨" prop="ShipTonnage">
              <el-input
                v-model="addForm.ShipTonnage"
                placeholder="请输入船舶总吨"
                onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
                maxlength="9"
              ></el-input>
            </el-form-item>
            <el-form-item label="建成日期" prop="ShipCreateDate">
              <el-date-picker
                v-model="addForm.ShipCreateDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择建成日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="改建日期" prop="ShipRenovationDate">
              <el-date-picker
                v-model="addForm.ShipRenovationDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择改建日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="主机功率" prop="TotalPower">
              <el-input
                v-model="addForm.TotalPower"
                placeholder="请输入主机功率"
                onkeyup="value=value.replace(/[^\d.]/g,'').replace(/(\d)(\.)(\d*)(\2*)(\d*)/,'$1$2$3$5').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"
                maxlength="9"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" v-if="dialogType != 'detail'">
        <el-button size="medium" @click="showAddDialog = !showAddDialog">取消</el-button>
        <el-button type="primary" size="medium" @click="toStage()" v-if="!currentItem"
          >暂存</el-button
        >
        <el-button type="primary" size="medium" @click="toSubmit()" :loading="loading">{{
          currentItem && currentItem.Status == 1 ? "重新提交" : "提交资料"
        }}</el-button>
      </div>
      <div slot="footer" v-else>
        <el-button size="medium" @click="showAddDialog = !showAddDialog">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 上传数据错误信息 -->
    <el-dialog :visible.sync="uploadErrorDialog" title="错误说明" center append-to-body>
      <div style="height: 60vh; overflow-y: scroll">
        <el-table
          :data="uploadErrorList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        >
          <el-table-column
            align="center"
            prop="LineNumber"
            label="错误行"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Msg"
            label="错误信息"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
    :close-on-click-modal="false"
      title="提示"
      :visible.sync="importListDialogVisible"
      width="75%"
      :show-close="false"
    >
 
      <el-table
        ref="multipleTable"
        v-if="!progressIng"
        :data="importList"
        height="500px"
        @selection-change="tableSelectionChange"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
          fixed="left"
        ></el-table-column>
        <el-table-column align="center" type="index" label="序号" fixed="left" width="50">
        </el-table-column>

        <el-table-column
          v-for="(item, index) in importListColumns"
          :key="index"
          :fixed="item.fixed"
          :align="item.align"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type == 'string'">
              {{ scope.row[item.prop] || "--------" }}</span
            >
            <div v-else>
              <el-image
                style="width: 100px; height: 100px"
                :preview-src-list=" [scope.row[item.prop]]"
                :src="scope.row[item.prop]"
                fit="cover"
              ></el-image>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column align="center" fixed="right" label="操作" width="100">
          <template slot-scope="scope"> </template>
        </el-table-column> -->
      </el-table>

      <div
        v-if="progressIng"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: white;
          height: calc(50vh - 106px);
        "
      >
        <el-progress type="circle" :percentage="progress"></el-progress>
        <p style="margin-top: 8px; font-weight: 700; font-size: 18px">同步图片中...</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          :disabled="selectedImportList.length == 0"
          @click="deleteImportList"
          >删除选中的行</el-button
        >
        <el-button type="primary"     :disabled="!saveImportClive" @click="saveImport">保存</el-button>
        <el-button @click="closeOpen"  :disabled="!saveImportClive">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const importListColumns = [
  {
    fixed: "left",
    align: "center",
    prop: "ShipName",
    width: "200",
    label: "船舶名称",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipRegNumber",
    width: "150",
    label: "船舶登记号",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipType",
    width: "200",
    label: "船舶类型",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipNO",
    width: "150",
    label: "船舶编号",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipPort",
    width: "150",
    label: "船籍港口",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipCheckNumber",
    width: "150",
    label: "船检登记号",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "Shipowner",
    width: "150",
    label: "船舶所有人",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipOperator",
    width: "150",
    label: "船舶经营人",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "UsagePeriodDate",
    width: "150",
    label: "使用期限",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "IssuingAuthority",
    width: "150",
    label: "发证机关",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "IssuingDate",
    width: "150",
    label: "发证日期",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipBodyMaterial",
    width: "150",
    label: "船舶材料",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipTonnage",
    width: "150",
    label: "船舶总吨",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipCreateDate",
    width: "150",
    label: "建成日期",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipRenovationDate",
    width: "150",
    label: "改建日期",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "TotalPower",
    width: "150",
    label: "主机功率",
    type: "string",
  },
  {
    fixed: "right",
    align: "center",
    prop: "RiverLicenseURL",
    width: "150",
    label: "船舶内河适航证",
    type: "img",
  },
  {
    fixed: "right",
    align: "center",
    prop: "ShipLicenseFrontURL",
    width: "150",
    label: "船舶营运证正面",
    type: "img",
  },
  {
    fixed: "right",
    align: "center",
    prop: "ShipLicenseBackURL",
    width: "150",
    label: "船舶营运证反面",
    type: "img",
  },
  {
    fixed: "right",
    align: "center",
    prop: "ShipPhotoURL",
    width: "150",
    label: "船舶图片",
    type: "img",
  },
  {
    fixed: "right",
    align: "center",
    prop: "AISURL",
    width: "150",
    label: "AIS证书",
    type: "img",
  },
  {
    fixed: "right",
    align: "center",
    prop: "RadioLicenseURL",
    width: "150",
    label: "电台执照",
    type: "img",
  },
];
import {
  ShipPage,
  ShipAdd,
  ShipEdit,
  StagingSet,
  StagingGet,
  ShipDetail,
  ShipSubmit,
  ShipSubmitList,
  Import,
  ImportList,
  ImportListImgSrc,
  Export,
  Delete,
  DownTemplate,
} from "@/api/transport/ship/shipping";
import UploadImg from "@/components/commonCmpt/uploadImg";
import { upLoadImgByUrl } from "@/api/common/common";
import { _getUserId } from "@/utils/storage";
const pics = [
  "RiverLicenseURL",
  "ShipLicenseFrontURL",
  "ShipLicenseBackURL",
  "ShipPhotoURL",
  "Aisurl",
  "RadioLicenseURL",
];
export default {
  components: {
    UploadImg,
  },
  data() {
    return {
      //完成
      importIndex: 0,
      saveImportClive:true,
      importListDialogVisible: false,
      isRecipient: false,
      progressIng: false,
      progress: 0,
      importList: [],
      selectedImportList: [],
      importListColumns,
      activeCmpt: "0",
      statusList: [
        {
          statusName: "待提交",
          status: "0",
          timeTxt: "添加时间",
        },
        {
          statusName: "待审核",
          status: "10",
          timeTxt: "提交时间",
        },
        {
          statusName: "已通过",
          status: "100",
          timeTxt: "审核时间",
        },
        {
          statusName: "已驳回",
          status: "1",
          timeTxt: "驳回时间",
        },
      ],
      queryForm: {
        ShipNO: "",
        ShipName: "",
        ShipType: "",
        ShipRegNumber: "",
        searchDate: ["", ""],
      },
      tableData: [],
      pagination: {
        pagesize: 10,
        page: 1,
        total: 0,
      },
      loading: false,
      multipleSelection: [],
      showAddDialog: false,
      // 表单校验
      addRule: {
        ShipName: [{ required: true, message: "请输入船舶名称", trigger: "change" }],
        ShipRegNumber: [
          { required: true, message: "请输入船舶登记号", trigger: "change" },
        ],
        ShipType: [{ required: true, message: "请输入船舶类型", trigger: "change" }],
        ShipNO: [{ required: true, message: "请输入船舶编号", trigger: "change" }],
      },
      // 提交表单
      addForm: {
        AscriptionUserID: 0,
        ShipNO: "",
        ShipName: "",
        ShipPort: "",
        ShipRegNumber: "",
        ShipCheckNumber: "",
        Shipowner: "",
        ShipOperator: "",
        UsagePeriodDate: "",
        IssuingAuthority: "",
        IssuingDate: "",
        ShipType: "",
        ShipBodyMaterial: "",
        ShipTonnage: 0,
        ShipCreateDate: "",
        ShipRenovationDate: "",
        PowerType: "",
        TotalPower: 0,
        Remark: "",
      },
      authForm: {
        // 船舶内河适航证
        RiverLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶内河适航证",
        },
        //船舶营运证正面
        ShipLicenseFrontURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶营运证正面",
        },
        //船舶营运证反面
        ShipLicenseBackURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶营运证反面",
        },
        //船舶图片
        ShipPhotoURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "船舶图片",
        },
        //AIS证书
        Aisurl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "AIS证书",
        },
        //电台执照
        RadioLicenseURL: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
          text: "电台执照",
        },
      },
      currentFile: {},
      dialogType: "", // 弹窗类型
      currentItem: "", // 当前操作数据
      srcList: [],
      uploadErrorDialog: false,
      uploadErrorList: [],
    };
  },
  created() {
    this.getShipPage();
  },
  methods: {
    // 查询船舶列表
    getShipPage() {
      this.loading = true;
      let data = {
        AscriptionUserID: parseInt(_getUserId()) / 5,
        ShipType: this.queryForm.ShipType,
        ShipNO: this.queryForm.ShipNO,
        ShipRegNumber: this.queryForm.ShipRegNumber,
        ShipName: this.queryForm.ShipName,
        status: Number(this.activeCmpt),
        statusTimeStart:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[0] &&
          this.queryForm.searchDate[0] + " 00:00:00",
        statusTimeEnd:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[1] &&
          this.queryForm.searchDate[1] + " 23:59:59",
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
      };
      ShipPage(data)
        .then((res) => {
          this.pagination.total = res.data.TotalCount;
          this.tableData = res.data.DataList;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 换页
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getShipPage();
    },
    //分页数量改变
    sizeChange(val) {
      this.pagination.pagesize = val;
      this.getShipPage();
    },
    //搜索
    search() {
      this.pagination.page = 1;
      this.getShipPage();
    },
    // 清空
    resetForm() {
      this.queryForm = {
        ShipNO: "",
        ShipName: "",
        ShipType: "",
        ShipRegNumber: "",
        searchDate: ["", ""],
      };
      this.search();
      this.multipleSelection = [];
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增编辑
    toAdd(type, item) {
      this.dialogType = "";
      this.currentItem = item;
      if (type == "add") {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        StagingGet()
          .then((res) => {
            let stageInfo = res.data ? JSON.parse(res.data) : {};
            if (stageInfo.shipBaseInfo && Object.keys(stageInfo.shipBaseInfo).length) {
              this.addForm = JSON.parse(JSON.stringify(stageInfo.shipBaseInfo));
            } else {
              this.addForm = {
                AscriptionUserID: 0,
                ShipNO: "",
                ShipName: "",
                ShipPort: "",
                ShipRegNumber: "",
                ShipCheckNumber: "",
                Shipowner: "",
                ShipOperator: "",
                UsagePeriodDate: "",
                IssuingAuthority: "",
                IssuingDate: "",
                ShipType: "",
                ShipBodyMaterial: "",
                ShipTonnage: 0,
                ShipCreateDate: "",
                ShipRenovationDate: "",
                PowerType: "",
                TotalPower: 0,
                Remark: "",
              };
            }
            if (
              stageInfo.shipConfirmInfo &&
              Object.keys(stageInfo.shipConfirmInfo).length
            ) {
              for (let i = 0; i < pics.length; i++) {
                let obj = pics[i];
                this.authForm[obj].picUrl = stageInfo.shipConfirmInfo[obj];
              }
            } else {
              this.authForm = {
                // 船舶内河适航证
                RiverLicenseURL: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "船舶内河适航证",
                },
                //船舶营运证正面
                ShipLicenseFrontURL: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "船舶营运证正面",
                },
                //船舶营运证反面
                ShipLicenseBackURL: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "船舶营运证反面",
                },
                //船舶图片
                ShipPhotoURL: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "船舶图片",
                },
                //AIS证书
                Aisurl: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "AIS证书",
                },
                //电台执照
                RadioLicenseURL: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  text: "电台执照",
                },
              };
            }
            this.showAddDialog = true;
          })
          .finally(() => {
            loading.close();
          });
      } else {
        this.getDetail("edit", item.ID);
      }
    },
    // 获取详情
    getDetail(type, id) {
      this.dialogType = type;
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.srcList = [];
      ShipDetail({ id })
        .then((res) => {
          this.addForm = JSON.parse(JSON.stringify(res.data.ShipBaseInfo));
          for (let i = 0; i < pics.length; i++) {
            let obj = pics[i];
            if (obj == "Aisurl") {
              this.authForm["Aisurl"].picUrl = res.data.ShipConfirmInfo["AISURL"];
            } else {
              this.authForm[obj].picUrl = res.data.ShipConfirmInfo[obj];
            }
            this.srcList.push(this.authForm[obj].picUrl);
          }
          this.showAddDialog = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    toSubmit() {
      let shipConfirmInfo = {};
      for (let i = 0; i < pics.length; i++) {
        let obj = pics[i];
        if (i < 4) {
          if (!this.authForm[obj].picUrl) {
            this.$message.warning(`请上传${this.authForm[obj].text}！`);
            return;
          }
        } else {
          if (!this.authForm[pics[4]].picUrl && !this.authForm[pics[5]].picUrl) {
            this.$message.warning(
              `${this.authForm[pics[4]].text}和${
                this.authForm[pics[5]].text
              }必须上传其中一张！`
            );
            return;
          }
        }
        shipConfirmInfo[obj] = this.authForm[obj].picUrl;
      }
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let ajaxFun = this.currentItem ? ShipEdit : ShipAdd;
          ajaxFun({
            AscriptionUserID: parseInt(_getUserId()) / 5,
            shipBaseInfo: this.addForm,
            shipConfirmInfo: shipConfirmInfo,
            ...(this.currentItem ? { id: this.currentItem.ID } : {}),
          })
            .then((res) => {
              if (!this.currentItem) {
                // 清空缓存
                StagingSet({
                  json: JSON.stringify({
                    shipBaseInfo: {},
                    shipConfirmInfo: {},
                  }),
                }).then((res) => {});
              }
              if (this.currentItem && this.currentItem.Status == 1) {
                ShipSubmit({
                  id: this.currentItem.ID,
                })
                  .then((res) => {
                    debugger;
                    this.$message.success("操作成功");
                    this.showAddDialog = false;
                    this.getShipPage();
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              } else {
                this.loading = false;
                this.$message.success("操作成功");
                this.showAddDialog = false;
                this.getShipPage();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    // 删除
    toDelete(type, item) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        if (type == "one") {
          ids = [item.ID];
        } else {
          this.multipleSelection.map((it) => {
            ids.push(it.ID);
          });
        }
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ids.map(async (it, idx) => {
          await Delete({
            id: it,
          })
            .then((res) => {})
            .catch(() => {
              loading.close();
            });
          if (idx == ids.length - 1) {
            this.$message.success("操作成功");
            this.getShipPage();
            this.multipleSelection = [];
            loading.close();
          }
        });
      });
    },
    // 暂存
    toStage() {
      let shipConfirmInfo = {};
      for (let i = 0; i < pics.length; i++) {
        let obj = pics[i];
        shipConfirmInfo[obj] = this.authForm[obj].picUrl;
      }
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      StagingSet({
        json: JSON.stringify({
          shipBaseInfo: this.addForm,
          shipConfirmInfo: shipConfirmInfo,
        }),
      })
        .then((res) => {
          this.$message.success("操作成功");
          this.showAddDialog = false;
        })
        .finally(() => {
          loading.close();
        });
    },
    toExamineOne(item) {
      this.$confirm("确定提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "提交审核中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipSubmit({
          id: item.ID,
        })
          .then((res) => {
            this.$message.success("操作成功");
            this.getShipPage();
            this.multipleSelection = [];
          })
          .catch(() => {})
          .finally(() => {
            loading.close();
          });
      });
    },
    // 提交审核
    toExamine(type, item) {
      this.$confirm("确定提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        if (type == "one") {
          ids = [item.ID];
        } else {
          this.multipleSelection.map((it) => {
            ids.push(it.ID);
          });
        }
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipSubmitList(ids)
          .then((res) => {
            this.$message.success("操作成功");
            this.getShipPage();
            this.multipleSelection = [];
          })
          .finally(() => {
            loading.close();
          });
        // ids.map(async (it, idx) => {
        //   await ShipSubmit({
        //     id: it,
        //   })
        //     .then((res) => {})
        //     .catch(() => {
        //       loading.close();
        //     });
        //   if (idx == ids.length - 1) {
        //     this.$message.success("操作成功");
        //     this.getShipPage();
        //     this.multipleSelection = [];
        //     loading.close();
        //   }
        // });
      });
    },
    // 查看详情
    toDetail(item) {
      this.getDetail("detail", item.ID);
    },
    // 导出表格
    toExport() {
      let params = {
        AscriptionUserID: parseInt(_getUserId()) / 5,
        ShipType: this.queryForm.ShipType,
        ShipNO: this.queryForm.ShipNO,
        ShipName: this.queryForm.ShipName,
        ShipRegNumber: this.queryForm.ShipRegNumber,
        status: Number(this.activeCmpt),
        statusTimeStart:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[0] &&
          this.queryForm.searchDate[0] + " 00:00:00",
        statusTimeEnd:
          this.queryForm.searchDate &&
          this.queryForm.searchDate[1] &&
          this.queryForm.searchDate[1] + " 23:59:59",
        pageIndex: this.pagination.page,
        pageSize: this.pagination.pagesize,
      };
      const loading = this.$loading({
        lock: true,
        text: "导出表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Export(params, {
        responseType: "blob",
      }).catch((err) => {
        let { data } = err;
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg}`,
              type: "error",
            });
          };
          return;
        }

        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"].split(";")[1].split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
    // 下载模板
    toDownTemplate() {
      DownTemplate().then((res) => {
        if (res.data) {
          this.$message.success("下载成功");
        }
        window.location.href = res.data;
      });
    },
    tableSelectionChange(e) {
      this.selectedImportList = e;
    },
    deleteImportList() {
      if (this.selectedImportList.length == 0) {
        this.$message.warning("暂未选中行");
        return;
      }
      this.selectedImportList.forEach((row) => {
        var index = this.importList.findIndex((x) => x == row);
        this.importList.splice(index, 1);
      });
    },
    closeOpen() {
      this.importListDialogVisible = false;
      this.progressIng = false;
      this.progress = 0;
      this.selectedImportList = [];
      this.importList = [];
      this.saveImportClive=true;
      this.saveImportListImgSrcIsClive = false;
      this.importIndex = 0;
    },
    saveImport() {
      if (this.importList.length == 0) {
        this.$message.warning("暂无数据");
        return;
      }
      this.$confirm("是否同步照片", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.saveImportClive=false;
          this.saveImportListImgSrc();
        })
        .catch(() => {
          this.saveImportClive=false;
          this.saveImportBase();
        });
    },
    saveImportBase() {
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = this.importList;
      Import(data.reverse())
        .then((res) => {
          let { data } = res;
          this.$message.success("导入成功");
          this.getShipPage();
          setTimeout(() => {
            if (data == null || data == "") {
              return;
            }
            this.$message.error(
              !this.isRecipient
                ? "存在错误船东,正在下载文件"
                : "存在错误代收人，正在下载文件"
            );
            const elink = document.createElement("a");
            elink.style.display = "none";
            elink.href = data;
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }, 2000);
        })
        .finally(() => {
          this.closeOpen();
          loading.close();
        });
    },
    saveImportListImgSrc() {
      this.progressIng = true;
      for (let index = 0; index < this.importList.length; index++) {
        const element = this.importList[index];
        ImportListImgSrc(element).then((res) => {
          this.importList[index].RiverLicenseURL = res.data.RiverLicenseURL;
          this.importList[index].ShipLicenseFrontURL = res.data.ShipLicenseFrontURL;
          this.importList[index].ShipLicenseBackURL = res.data.ShipLicenseBackURL;
          this.importList[index].ShipPhotoURL = res.data.ShipPhotoURL;
          this.importList[index].AISURL = res.data.AISURL;
          this.importList[index].RadioLicenseURL = res.data.RadioLicenseURL;
          this.importIndex = this.importIndex + 1;
          this.progress = parseInt((this.importIndex / this.importList.length) * 100);
          if (this.progress == 100) {
            //完成
            this.importIndex = 0;
            this.progressIng = false;
            this.progress = 0;
            this.$message.success("同步照片完成,即将保存船东列表");
            setTimeout(() => {
              this.saveImportBase();
            }, 800);
          }
        });
      }
    },
    // 上传表格
    handleUploadData(e) {
      const loading = this.$loading({
        lock: true,
        text: "上传表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      ImportList(formData)
        .then((res) => {
          this.importListDialogVisible = true;
          this.$nextTick(() => {
            this.importList = res.data;
          });
          // let { data } = res;
          // this.getShipPage();
          // if ((data == null || data == "")) {
          //   return;
          // }
          // this.$message.error("存在错误船舶信息,正在下载文件");
          // // 非IE下载
          // const elink = document.createElement("a");
          // elink.style.display = "none";
          // elink.href = data;
          // document.body.appendChild(elink);
          // elink.click();
          // URL.revokeObjectURL(elink.href); // 释放URL 对象
          // document.body.removeChild(elink);
          // if (!res.data.length) {
          //   this.getShipPage();
          // } else {
          //   this.uploadErrorList = res.data;
          //   this.uploadErrorDialog = true;
          // }
        })
        .finally(() => {
          this.$refs.uploadData.value = null;
          loading.close();
        });
    },
    //上传证件
    uploadCertificate(field) {
      field.loading = true;
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Ship" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            if (res.data) {
              field.picUrl = res.data;
            }
          })
          .catch(() => {})
          .finally(() => {
            field.loading = true;
            field.uploaded = true;
          });
      };
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.shipping {
  .topbtns {
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 1px solid #eee;
  }
}

.showAddDialog {
  ::v-deep .el-dialog {
    margin-top: 6vh !important;
  }

  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .addForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      margin-bottom: 10px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: #2081ff;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .upBox {
        text-align: center;

        .upText {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0 0 0;
          color: #333333;
        }
      }

      .el-upload__tip {
        color: #7e7e7e;
      }
    }

    .img {
      width: 278px;
      height: 174px;
      border-radius: 10px;
    }
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
