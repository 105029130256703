import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 查询船舶列表
export const ShipPage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Page`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 添加船舶信息
export const ShipAdd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Add`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 编辑船舶信息
export const ShipEdit = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Edit`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 暂存信息
export const StagingSet = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/StagingSet`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取暂存信息
export const StagingGet = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/StagingGet`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询船舶明细
export const ShipDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Detail`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const PageStatus= (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/PageStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交审核
export const ShipSubmit = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Submit`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交审核
export const ShipSubmitList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/SubmitList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核驳回
export const ShipReject = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Reject`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核同意
export const ShipAgree = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Agree`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入船舶
export const Import = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Import`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


export const  ImportList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/ImportList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const  ImportListImgSrc = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/ImportListImgSrc`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
} 



// 导出船舶
export const Export = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Export`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除船舶
export const Delete = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Ship/Delete`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载导入模板
export const DownTemplate = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/Ship/DownTemplate`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}